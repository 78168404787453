export const isDev = process.env.VUE_APP_env !== "prod";
export const apiGatewayId =
  process.env.VUE_APP_env === "prod" ? "5g93jjndba" : "5ls68979a3";

//Prod config:
// export const isDev = false;
// export const apiGatewayId = "5g93jjndba";

export const siteLogo = {
  "fainyi3d.com": "fainyi.ico",
  "3dplast.biz": "3dplast.jpg",
  "shop.plexiwire.com.ua": "plexiwire.ico",
  "monofilament.com.ua": "monofav.png",
  "lbl-corp.com": "lbl.ico",
  "u3df.com.ua": "u3df.ico",
  "pochatok-filament.uaprom.net": "pochatok.ico",
  "3dfilament.com.ua": "3dfil.jpg",
  "artline.ua": "artline.ico",
  "3d4u.com.ua": "3dfu.ico",
  "dasplast.com": "dasplast.jpg",
  "boze.com.ua": "boze.png",
  "filament.org.ua": "filamentua.webp",
  "hemoplast.prom.ua": "hemoplast.webp",
  "polydream.com.ua": "polydream.png",
  "techfil.prom.ua": "techfil.jpg",
  "3dsunlu.com.ua": "sunlu.webp",
  "prosteer.prom.ua": "elegoo.png",
  "pyd.com.ua": "pyd.svg",
  "vvm-filament.prom.ua": "vvm.png",
  "vitatoys3d.prom.ua": "vita-toys.webp",
};
