<template>
  <div class="container">
    <v-select
      multiple
      clearable
      label="Кольори"
      :items="colors"
      :item-title="null"
      v-model="filters.color"
    >
      <template v-slot:prepend-item>
        <v-list-item class="d-flex justify-center" @click="allColors">
          <template v-slot:prepend>
            <v-btn>Обрати все</v-btn>
          </template>
        </v-list-item>
        <v-divider class=""></v-divider>
      </template>
      <template v-slot:item="{ props, item }">
        <v-list-item>
          <v-list-item v-bind="props">
            <template v-slot:title="">
              <div class="select-icon">
                <FilamentIcon :color="item.value"></FilamentIcon>
              </div>
            </template>
          </v-list-item>
        </v-list-item>
      </template>
      <template v-slot:selection="{ item, index }">
        <FilamentIcon :size="16" hide-title :color="item.value"></FilamentIcon>
      </template>
    </v-select>
    <v-select
      multiple
      clearable
      label="Категорії"
      :items="category"
      v-model="filters.category"
    >
      <template v-slot:prepend-item>
        <v-list-item class="d-flex justify-center" @click="allCategories">
          <template v-slot:prepend>
            <v-btn>Обрати все</v-btn>
          </template>
        </v-list-item>
        <v-divider class=""></v-divider>
      </template>
    </v-select>
    <v-select
      multiple
      clearable
      label="Сайти"
      :items="site"
      v-model="filters.site"
    >
      <template v-slot:prepend-item>
        <v-list-item class="d-flex justify-center" @click="allSites">
          <template v-slot:prepend>
            <v-btn>Обрати все</v-btn>
          </template>
        </v-list-item>
        <v-divider class=""></v-divider>
      </template>
      <template v-slot:item="{ props, item }">
        <v-list-item>
          <v-list-item v-bind="props">
            <template v-slot:title="">
              <img class="site-icon" :src="'/logos/' + siteLogo[item.value]" />
              {{ item.value }}
            </template>
          </v-list-item>
        </v-list-item>
      </template>
      <template v-slot:selection="{ item }">
        <img class="site-icon" :src="'/logos/' + siteLogo[item.value]" />
      </template>
    </v-select>
    <!-- <v-checkbox label="Показати все" v-model="showAll.show" /> -->
    <v-btn elevated @click="reset" :disabled="!filtersEnabled"
      >Скинути фільтри</v-btn
    >
    <v-footer absolute app elevation="3">
      <label
        >Зворотній звязок:
        <a href="https://t.me/d_r_d_r_d_r">телеграм</a></label
      >
    </v-footer>
  </div>
</template>
<script>
import { useFiltersStore, useShowAll } from "@/store";
import FilamentIcon from "@/components/FilamentIcon.vue";
import BaseMixin from "@/mixins/Base.vue";
import { siteLogo } from "@/constants";

export default {
  name: "Filters",
  components: {
    FilamentIcon,
  },
  mixins: [BaseMixin],
  setup() {
    return {
      siteLogo: siteLogo,
      colors: [
        "black",
        "white",
        "green",
        "red",
        "grey",
        "transp",
        "brown",
        "orange",
        "yellow",
        "khaki",
        "blue",
        "violet",
        "lightgreen",
        "lightblue",
        "pink",
        "pearl",
        "turquoise",
        "beige",
        "metal",
        "rainbow",
        "no-color",
        "???",
      ],
      site: [
        "fainyi3d.com",
        "3dplast.biz",
        "shop.plexiwire.com.ua",
        "monofilament.com.ua",
        "lbl-corp.com",
        "u3df.com.ua",
        "pochatok-filament.uaprom.net",
        "3dfilament.com.ua",
        "artline.ua",
        "3d4u.com.ua",
        "dasplast.com",
        "boze.com.ua",
        "filament.org.ua",
        "hemoplast.prom.ua",
        "polydream.com.ua",
        "techfil.prom.ua",
        "3dsunlu.com.ua",
        "prosteer.prom.ua",
        "pyd.com.ua",
        "vvm-filament.prom.ua",
        "vitatoys3d.prom.ua",
      ],
      category: ["PETG", "PLA", "NYLON", "ABS", "TPU", "ASA"],
    };
  },
  data: () => {
    return {
      filters: useFiltersStore(),
      showAll: useShowAll(),
    };
  },
  methods: {
    allColors() {
      if (this.filters.color.length === this.colors.length) {
        this.filters.color = [];
      } else {
        this.filters.color = [...this.colors];
      }
    },
    allCategories() {
      if (this.filters.category.length === this.category.length) {
        this.filters.category = [];
      } else {
        this.filters.category = [...this.category];
      }
    },
    allSites() {
      if (this.filters.site.length === this.site.length) {
        this.filters.site = [];
      } else {
        this.filters.site = [...this.site];
      }
    },
    reset() {
      this.filters.site = this.filters.category = this.filters.color = [];
    },
  },
};
</script>
<style scoped>
.select-icon {
  margin-left: -110px;
}
.container {
  height: 100%;
}
.v-footer {
  width: 100%;
}
</style>
<style>
.site-icon {
  width: 24px;
  height: 24px;
}
</style>


